import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

initializeApp(firebaseConfig);
const db = getFirestore();

export const authenticateAnonymously = () => {
  const auth = getAuth();
  return signInAnonymously(auth);
};

export const getPrices = () => {
  const pricesDocRef = doc(db, "prices", "USDPh");
  return getDoc(pricesDocRef);
};

export const checkUser = async (email_id) => {
  const userData = await axios.post("https://api.programminghub.io/v2/api/auth/individual/link", {
    email_id,
  });
  const userType = await axios.post("https://api.programminghub.io/v2/api/subscriptionPayment/addPaymentDetails", {
    paymentInfo: [],
    type: "web",
    userId: userData.data.data.user_id
  });
  return { status: userType.data.pro_status, userId: userData.data.data.user_id };
};

export const makeUserPro = async (email, user_id) => {
  await axios.post(
    "https://api.programminghub.io/v2/api/auth/addProUser",
    {
      email,
      user_id,
      promo_code: "DHUY6628",
      code_type: "ONETIME",
      expiry_time: "2114-10-13T12:39:00.000Z"
    }
  );
};
