import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddressForm from "./AddressForm";
import { Product } from "./Product";
import "../App.css";
import { checkUser } from "../services/firestore";
import { UserContext } from "../contexts/user";
import PHLogo from "../components/PHLogo";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://programminghub.io/">
        Programming Hub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["Email", "Review your order"];

function getStepContent(step) {
  const product = {
    name: "Programming Hub Pro",
    description: "Full access to all premium Programming Hub courses",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9STqsswu22SzgYkf1v9600uMj0Dwwt3zNcw&usqp=CAU",
  };
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <Product product={product} />;
    default:
      throw new Error("Unknown step");
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: "#4aa5da"
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    }
  },
});

export function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, dispatch] = React.useContext(UserContext);
  const [type, setType] = React.useState(0);
  const [nextDisable, setNextDisable] = React.useState(false);

  const handleNext = async () => {
    setNextDisable(true);
    await checkUser(state.email).then((status) => {
      dispatch({ email: state.email, userId: status.userId });
      if (status.status === false) {
        setActiveStep(activeStep + 1);
      }
      setType(status.status);
    }).catch((err) => {
      if (err.message.includes("400")) {
        setType(2);
      } else {
        setType(3);
      }
    }
    );
    setNextDisable(false);
  };

  const handleBack = () => {
    dispatch({ email: "" });
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          backgroundColor: "#4aa5da"
        }}
      >
        <Container sx={{ ml: 0, pt: 2, pb: 2 }}>
          <PHLogo />
        </Container>
      </AppBar>
      {type === true ? (
        <div className="green center">
          <Typography variant="h6" color="inherit" sx={{
            padding: 2
          }}>
            Woah! You are already a PRO Member enjoying the highest privileges on our app 🥳
          </Typography>
        </div>
      ) : type === 2 ? (
        <div className="red center">
          <Typography variant="h6" color="inherit" sx={{
            padding: 2
          }}>
            Oops! This user ID does not exist in our records. Please signup on the app first and then retry! 😟
          </Typography>
        </div>
      ) :
        type === 3 ? (
          <div className="red center">
            <Typography variant="h6" color="inherit" sx={{
              padding: 2
            }}>
              An error occurred. Please try again later.
            </Typography>
          </div>
        ) : (
          <></>
        )}
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3, backgroundColor: "white" } }}
        >
          <React.Fragment>
            {
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1, color: "black" }}>
                      Back
                    </Button>
                  )}

                  {activeStep !== steps.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={nextDisable}
                      sx={{
                        mt: 3, ml: 1, backgroundColor: "white", color: "black", '&:hover': {
                          backgroundColor: '#4aa5da',
                          color: 'white',
                        },
                      }}
                    >
                      {"Next"}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>
              </React.Fragment>
            }
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
